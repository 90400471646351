import styled from "styled-components";

const ContainerFormDeleteAlbum = styled.form`
  width: 100%;
  height: calc(100vh - 80px);
  padding: 0px 20px;

  overflow-y: scroll;

  position: relative;

  h4 {
    margin-bottom: 10px;
  }

  .box-container-photos {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    gap: 30px;

    overflow-x: scroll;
    overflow-y: hidden;

    margin-top: 14px;
    padding-top: 15px;

    &::-webkit-scrollbar {
      display: none;
    }

    .loading-overflow {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background-color: rgba(0, 0, 0, 0.1);

      z-index: -9999;

      &.front {
        z-index: 9999;
      }
    }

    .box-photo {
      background-color: rgba(0, 0, 0, 0.1);
      padding: 20px;

      width: 122px;
      min-width: 122px;
      height: 122px;
      min-height: 122px;

      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 34px;
      color: rgba(0, 0, 0, 0.6);

      border-radius: 5px;

      cursor: pointer;

      input[type="file"] {
        opacity: 0;
        display: none;
      }
    }

    margin-bottom: 90px;
  }

  .float-button {
    width: 460px;
    position: fixed;
    bottom: 0;
    margin-bottom: 20px;
  }
`;

export { ContainerFormDeleteAlbum };
