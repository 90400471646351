import styled from "styled-components";

const ContainerFormCreateAlbum = styled.form`
  width: 100%;
  height: calc(100vh - 80px);
  padding: 0px 20px;

  overflow-y: scroll;

  position: relative;

  h4 {
    margin-bottom: 10px;
  }

  .float-button {
    width: 460px;
    position: fixed;
    bottom: 0;
    margin-bottom: 20px;
  }
`;

export { ContainerFormCreateAlbum };
