import React, { useEffect } from "react";

import { ContainerHeaderLayout } from "./styles";
import { gsap } from "gsap";

export type HeaderLayoutProps = {
  title?: string;
  description?: string;
  noBackgroundColor?: boolean;
  children?: React.ReactNode;
  actionButtons?: React.ReactNode;
};

export default function HeaderLayout({
  title,
  description,
  children,
  noBackgroundColor,
  actionButtons,
}: HeaderLayoutProps) {
  useEffect(() => {
    const selector1 = window.document.getElementsByClassName(
      "container-header-layout-1"
    );
    const selector2 = window.document.getElementsByClassName(
      "container-header-layout-2"
    );
    gsap.to(selector1, {
      opacity: 1,
      duration: 0.3,
    });
    gsap.to(selector1, {
      marginTop: 0,
      duration: 0.3,
    });
    gsap.to(selector2, {
      opacity: 1,
      duration: 0.6,
    });
    gsap.to(selector2, {
      marginTop: 10,
      duration: 0.6,
    });
  }, []);

  return (
    <ContainerHeaderLayout
      className={`${noBackgroundColor ? "noBackgroundColor" : ""}`}
    >
      <div className="top-header">
        <div className="top-header-left">
          <p className="container-header-layout-1">{title ?? "Index"}</p>
          <span className="container-header-layout-2">{description}</span>
        </div>
        <div className="top-header-right container-header-layout-1">
          {actionButtons}
        </div>
      </div>
      {children}
    </ContainerHeaderLayout>
  );
}
