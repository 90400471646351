import { IReduxNooxven } from "./props";

export const INITIAL_STATE: IReduxNooxven = {
  nooxven: {
    id: "01",
    name: "Grupo 4MR",
    appName: "Galeria de Images",

    // authorization: {
    //   appNammeLabelPriority: "4mr_token",
    // },
  },
};
