import { gsap } from "gsap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import { SubmitHandler, useForm } from "react-hook-form";

import Input from "@src/components/Input";
import AppLogo from "@src/components/Logo";
import Button from "@src/components/Button";

import { ContainerLogin } from "./styles";
import instanceApi from "@src/services/api";
import { IRequestApi, ResponseApi } from "@src/types/api";
import { IEmployee } from "@src/types/entity/employee";
import useNooxven from "@src/hooks/useNooxven";
import { NooxvenInstance } from "@src/index";

export type LoginFormProps = {
  email: string;
  password: string;
};

export default function LoginContainer() {
  const navigate = useNavigate();

  const { register, handleSubmit } = useForm<LoginFormProps>();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const { nooxven } = useNooxven();

  const onSubmit: SubmitHandler<LoginFormProps> = async (data) => {
    setErrorMessage(undefined);
    setIsLoadingButton(true);

    if (
      !data ||
      !data.email ||
      !data.password ||
      data.email.length <= 6 ||
      data.password.length < 7
    ) {
      setErrorMessage("Verifique os dados inseridos e tente novamente.");
      setIsLoadingButton(false);

      return;
    }

    const dataToRequest: IRequestApi<IEmployee> = {
      set: {
        email: data.email,
        password: data.password,
      },
    };

    try {
      const request: AxiosResponse<ResponseApi<any>> = await instanceApi.post(
        "/app/user/login",
        dataToRequest
      );

      if (!request.data.ok) {
        setErrorMessage(request.data.message ?? "Algo deu errado.");
        setIsLoadingButton(false);

        return;
      }

      const defaultToken = NooxvenInstance.getDefaultToken();

      console.log({
        defaultToken,
      });

      window.localStorage.setItem(defaultToken, request.data.result);

      setTimeout(() => {
        setIsLoadingButton(false);
        setTimeout(() => {
          hidScreen();
        }, 400);
      }, 1000);
    } catch (error) {
      const errorType = error as AxiosError;
      setIsLoadingButton(false);

      setErrorMessage(`${errorType.message ?? "Algo deu errado."}`);
    }
  };

  async function hidScreen() {
    const selector = window.document.getElementsByClassName("form-login");

    gsap.to(selector, {
      opacity: 0,
      duration: 0.3,
      onComplete: () => {
        setTimeout(() => {
          navigate("/app");
        }, 200);
      },
    });

    return true;
  }

  return (
    <ContainerLogin
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      autoCorrect="off"
      className="form-login"
    >
      <div className="top gsap-top-login">
        <AppLogo appName={nooxven?.name ?? "..."} />
        <h1 style={{ textAlign: "center" }}>{nooxven?.appName ?? "..."}</h1>
        <br />
        <Input
          hookForm={{
            ...register("email"),
            ...{ placeholder: "Email", type: "email", autoComplete: "off" },
          }}
        />
        <Input
          hookForm={{
            ...register("password"),
            ...{ placeholder: "Senha", type: "password", autoComplete: "off" },
          }}
        />
        {errorMessage ? (
          <div className="content-message-error">{errorMessage}</div>
        ) : (
          <></>
        )}
        <br />
        <Button type="submit" label="Entrar" isLoading={isLoadingButton} />
      </div>
      {/* <div className="bottom">
        <div className="link">Termos de Uso</div>
        <div className="link">Política de Privacidade</div>
      </div> */}
    </ContainerLogin>
  );
}
