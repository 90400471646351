import React from "react";
import { RiGalleryLine } from "react-icons/ri";

import { ContainerAppLogo } from "./styles";

export type AppLogoProps = {
  appName: string;
};

export default function AppLogo({ appName }: AppLogoProps) {
  return (
    <ContainerAppLogo className="content-app-logo">
      <div className="content-icon">
        <RiGalleryLine size={26} />
      </div>
      <span>{appName}</span>
    </ContainerAppLogo>
  );
}
