/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

import useAlbuns from "@src/hooks/useAlbuns";
import useBigModal from "@src/hooks/useBigModal";

import Input from "@src/components/Input";
import Button from "@src/components/Button";
import Textarea from "@src/components/Textarea";

import { ContainerFormEditAlbum } from "./styles";
import {
  AlbumTypeEnumType,
  IAlbum,
  IAlbumImage,
} from "@src/types/entity/album";
import InputSelect from "../InputSelect";
import SuccessBox from "../SuccessBox";
import UploadImagesV2 from "../UploadImagesV2";

export type FormUpdateProps = IAlbum;

export default function FormEditAlbum({ album }: { album: IAlbum }) {
  const { closeBigModal } = useBigModal();
  const { updateAlbum, retrieveAlbums } = useAlbuns();

  const { register, watch, setValue } = useForm<FormUpdateProps>({
    defaultValues: {
      ...album,
    },
  });

  const { setValue: setValueS, handleSubmit: handleSubmitS } =
    useForm<FormUpdateProps>({
      defaultValues: {
        _id: album._id,
      },
    });

  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<string | undefined>(undefined);

  const [initialImages, setInitialImages] = useState<IAlbumImage[] | undefined>(
    album.images
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const data = watch((v, n) => {
      // @ts-ignore
      setValueS(`${n.name}`, watch(`${n.name}`));
    });
  }, [watch]);

  // Fints single render
  // Used for remove cache data.
  useEffect(() => {
    const imagesToSet = album.images;
    imagesToSet?.map((v) => (v.toDelete = undefined));
    setInitialImages(imagesToSet);
  }, []);

  async function onSubmit(data: IAlbum) {
    setIsLoadingButton(true);

    try {
      const isToRemoveNotUploaded = initialImages?.filter((v) => {
        if (v.data?.toString().startsWith("data:image")) {
          if (v.toDelete === true) {
            return false;
          }
        }

        if (v.data?.toString().startsWith("http")) {
          if (v.toDelete === undefined) {
            return false;
          }
        }

        return true;
      });

      const albumToCreate: IAlbum = {
        title: data.title,
        description: data.description,
        imageCover: data.imageCover,
        type: data.type,
        images: isToRemoveNotUploaded,
      };

      const keys = Object.values(albumToCreate).filter(
        (v, _i) => v !== undefined
      );

      if (keys.length === 0) {
        throw new Error("Sem dados para atualizar.");
      }

      const removeEmpty = (obj: any) => {
        var newObj: any = {};
        Object.keys(obj).forEach((key) => {
          // @ts-ignore
          if (obj[key] === Object(obj[key])) {
            // @ts-ignore
            newObj[key] = removeEmpty(obj[key]);
          } else if (obj[key] !== undefined) {
            // @ts-ignore
            newObj[key] = obj[key];
          }
        });

        return newObj;
      };

      var cleanData = removeEmpty(albumToCreate);

      const preImages = cleanData["images"];

      if (preImages !== undefined) {
        const objectKeysOf = Object.keys(preImages);

        var newListImagesToReplace = [];

        for (var item1 of objectKeysOf) {
          newListImagesToReplace.push(preImages[`${item1}`]);
        }
        cleanData["images"] = newListImagesToReplace;
      }

      const updated = await updateAlbum({
        filter: {
          _id: watch("_id"),
        },
        set: cleanData,
      });

      if (updated) {
        setIsSuccess(true);
        setIsLoadingButton(false);

        await retrieveAlbums(
          {
            pagination: {
              page: 0,
              perPage: 20,
            },
            sort: {
              column: "createdAt",
              order: "desc",
            },
            returnMany: true,
          },
          true,
          true
        );
      } else {
        throw new Error("Algo deu errado. Tente novamente mais tarde.");
      }
    } catch (error) {
      setIsError(`${error}`);
      setIsLoadingButton(false);
    }
  }

  return (
    <ContainerFormEditAlbum onSubmit={handleSubmitS(onSubmit)}>
      {isSuccess === true ? (
        isError ? (
          <>
            {isError}
            <div className="float-button">
              <Button
                label="Voltar"
                type="button"
                onClick={() => {
                  setIsSuccess(false);
                  setIsError(undefined);
                }}
              />
            </div>
          </>
        ) : (
          <>
            <SuccessBox />
            <div className="float-button">
              <Button
                label="Fechar"
                type="button"
                onClick={() => {
                  closeBigModal();
                }}
              />
            </div>
          </>
        )
      ) : (
        <>
          <h4>Título do Álbum</h4>
          <Input
            hookForm={{
              ...register("title"),
              ...{ placeholder: "Título do Álbum", type: "text" },
            }}
          />
          <br />
          <h4>Descrição do Álbum</h4>
          <Textarea
            hookForm={{
              ...register("description"),
              ...{
                placeholder: "Descrição do trabalho realizado.",
                type: "text",
              },
            }}
          />
          <br />
          {/* Label Images */}
          <UploadImagesV2
            images={
              initialImages !== undefined
                ? (initialImages as IAlbumImage[])
                : []
            }
            onChange={(imgs) => {
              setInitialImages(imgs);
            }}
          />
          <br />
          {/* Label Categories */}
          <h4>Foto de Capa</h4>
          <div className="label-form-price-item">
            <InputSelect
              options={[
                ...Array.from(
                  {
                    // @ts-ignore
                    length:
                      initialImages !== undefined ? initialImages.length : 1,
                  },
                  (_, i) => i + 1
                ).map((v, _i) => {
                  return {
                    label: `${_i + 1}`,
                    value: `${_i + 1}`,
                  };
                }),
              ]}
              // @ts-ignore
              selected={watch("imageCover") ?? 1}
              onChange={(selected) => {
                // @ts-ignore
                setValue("imageCover", selected as number);
              }}
            />
          </div>
          <br />
          {/* Label Categories */}
          <h4>Tipo do Trabalho</h4>
          <div className="label-form-price-item">
            <InputSelect
              options={[
                {
                  label: "Construção Civíl",
                  value: "CONSTRUCTION",
                },
                {
                  label: "Serralharia",
                  value: "SAWMILL",
                },
                {
                  label: "Elétrica",
                  value: "ELECTRICAL",
                },
              ]}
              // @ts-ignore
              selected={(watch("type") as AlbumTypeEnumType) ?? "CONSTRUCTION"}
              onChange={(selected) => {
                const va = selected as AlbumTypeEnumType;
                // @ts-ignore
                setValue("type", va);
              }}
            />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className="float-button">
            <Button label="Salvar alterações" isLoading={isLoadingButton} />
          </div>
        </>
      )}
    </ContainerFormEditAlbum>
  );
}
