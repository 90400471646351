import { INITIAL_STATE } from "./data";
import { ActionProps, IReduxNooxven } from "./props";

export default function reducer(
  state = INITIAL_STATE,
  action: ActionProps
): IReduxNooxven {
  if (action.type === "SET_NOOXVEN") {
    return {
      ...state,
      nooxven: action.nooxven,
    };
  }

  return state;
}
