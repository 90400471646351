import styled from "styled-components";

export const ContainerHeaderLayout = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: #f4f4f4;

  padding: 30px 40px;

  &.noBackgroundColor {
    background-color: transparent;
  }

  .top-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .top-header-left {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      p {
        opacity: 0;
        font-size: 28px;
        line-height: 28px;
        font-weight: 700;
        margin-top: 10px;
      }
      span {
        opacity: 0;
        font-weight: 300;
        font-size: 16px;
        margin-top: 20px;
      }
    }
    .top-header-right {
      opacity: 0;
      margin-top: 20px;
    }
  }
`;
