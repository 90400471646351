import { gsap } from "gsap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";

import Input from "@src/components/Input";
import AppLogo from "@src/components/Logo";
import Button from "@src/components/Button";

import { ContainerLogin } from "./styles";
import useNooxven from "@src/hooks/useNooxven";

export type NewPasswordFormProps = {
  password: string;
  newPassword: string;
};

export default function NewPassword() {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm<NewPasswordFormProps>();

  const { nooxven } = useNooxven();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [isSuccessChanges, setIsSuccessChange] = useState(false);

  const onSubmit: SubmitHandler<NewPasswordFormProps> = (data) => {
    if (isSuccessChanges) {
      navigate("/");
      return;
    }

    if (
      !data ||
      !data.newPassword ||
      !data.password ||
      data.password !== data.newPassword
    ) {
      setErrorMessage("Verifique os dados inseridos e tente novamente.");

      setTimeout(() => {}, 1000);

      return;
    }
    setErrorMessage(undefined);
    //
    setIsLoadingButton(true);

    // Se Ok
    hidScreen();
  };

  async function hidScreen() {
    const selectorTop =
      window.document.getElementsByClassName("gsap-top-login");

    gsap.to(selectorTop, {
      opacity: 0,
      duration: 0.3,
      onComplete: () => {
        setIsSuccessChange(true);

        setTimeout(() => {
          gsap.to(selectorTop, {
            opacity: 1,
            duration: 0.3,
          });
        }, 100);
      },
    });
  }

  return (
    <ContainerLogin
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      autoCorrect="off"
      className="form-login"
    >
      <div className="top gsap-top-login">
        <AppLogo appName={nooxven?.name ?? ""} />
        {isSuccessChanges ? (
          <>
            <h1>Senha Atualizada</h1>
            <p>
              Pronto, nos próximos <b>logins</b> poderá utilizar a senha
              inserida
            </p>
            <Button
              type="submit"
              label="Fazer login Agora"
              isLoading={isLoadingButton}
            />
          </>
        ) : (
          <>
            <h1>Nova Senha</h1>
            <p>
              Insira a nova senha de acesso aos sistemas <b>Nxooven</b>
            </p>
            <Input
              hookForm={{
                ...register("password"),
                ...{ placeholder: "Nova Senha", type: "password" },
              }}
            />
            <Input
              hookForm={{
                ...register("newPassword"),
                ...{ placeholder: "Repetir nova Senha", type: "password" },
              }}
            />
            {errorMessage ? (
              <div className="content-message-error">{errorMessage}</div>
            ) : (
              <></>
            )}
            <Button
              type="submit"
              label="Salvar nova Senha"
              isLoading={isLoadingButton}
            />
          </>
        )}
      </div>
      <div className="bottom">
        <div className="link">Termos de Uso</div>
        <div className="link">Termos de Uso</div>
      </div>
    </ContainerLogin>
  );
}
