import { Routes, Route } from "react-router-dom";

import LayoutAuth from "@src/layouts/LayoutAuth";
import NewPassword from "@src/containers/auth/NewPassword";
import LoginContainer from "@src/containers/auth/LoginContainer";

export default function RoutesAuth() {
  return (
    <LayoutAuth>
      <Routes>
        <Route
          path="/*"
          element={
            <Routes>
              <Route path="/" element={<LoginContainer />} />
              <Route path="/new-password/:id/:code" element={<NewPassword />} />
            </Routes>
          }
        />
      </Routes>
    </LayoutAuth>
  );
}
