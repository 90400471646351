import { combineReducers } from "redux";

import bigModal from "./bigModal";
import employee from "./employee";
import albuns from "./album";
import nooxven from "./nooxven";

import { IReduxBigModal } from "./bigModal/props";
import { IReduxEmployee } from "./employee/props";
import { IReduxAlbuns } from "./album/props";
import { IReduxNooxven } from "./nooxven/props";

export default combineReducers({
  bigModal,
  employee,
  albuns,
  nooxven,
});

export type DefaultReducerProps = {
  bigModal?: IReduxBigModal;
  employee?: IReduxEmployee;
  albuns?: IReduxAlbuns;
  nooxven?: IReduxNooxven;
};
