export class Nooxven {
  getContract(): string {
    return "";
  }

  getDefaultToken(): string {
    const defaultToken = window.localStorage.getItem("default_token") ?? "";
    return defaultToken;
  }
}
