import styled from "styled-components";

export const ContainerInputSelect = styled.select`
  width: 100%;
  height: 46px;
  padding: 0px 10px;

  border-radius: 5px;
  font-size: 15px;
  line-height: 20px;

  position: relative;

  -webkit-appearance: none;
  appearance: none;

  border: 2px solid rgb(193, 193, 193);
`;
