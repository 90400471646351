import styled from "styled-components";

export const ContainerInput = styled.div`
  width: 100%;
  height: 46px;

  border: none;
  outline: none;

  cursor: pointer;

  padding: 0px 0px;

  border-radius: 5px;

  border: 2px solid #c1c1c1;

  input {
    width: 100%;
    height: 100%;

    border: none;
    outline: none;

    cursor: pointer;

    padding: 0px 0px 0px 10px;
    font-size: 15px;
    line-height: 20px;

    border-radius: 5px;

    &::placeholder {
      font-size: 15px;
      line-height: 15px;
    }
  }
`;
