/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { HiOutlinePhotograph } from "react-icons/hi";
import { UseFormRegister, UseFormWatch } from "react-hook-form";

import Spinner from "../Spinner";
import { IAlbumImage } from "@src/types/entity/album";
import { ContainerUploadImages, ContainerImageMiniPhoto } from "./styles";
import { ImagesManager } from "../UploadImagesV2/images_manager";

export type UploadImagesProps = {
  isView?: boolean;
  watch: UseFormWatch<any>;
  register: UseFormRegister<any>;
  onChange: (items: IAlbumImage[]) => void;
  label?: string;
  imagesEdit?: any[];
};

export default function UploadImages({
  label,
  isView,
  watch,
  imagesEdit,
  register,
  onChange,
}: UploadImagesProps) {
  const [isLoadingImages, setIsLoadingImages] = useState<boolean>(false);

  const imageManager = new ImagesManager();

  function updateState(items: IAlbumImage[]) {
    onChange(items);
  }

  function reverEditImages() {}

  function onRemoveImageFromArray(index: number) {
    const imagesToDelete = watch("images") as any[];

    var newListed: any[] = [];

    console.log({
      imagesToDelete,
      index,
    });

    for (let it = 0; it < imagesToDelete.length; it++) {
      console.log({
        it,
        entra: it !== index,
      });
      if (it !== index) {
        newListed.push(imagesToDelete[it]);
      }
    }

    console.log({
      newListed,
    });

    updateState(newListed);
  }

  const imagesToArray = [...watch("images")];

  console.log({
    imagesToArray,
  });

  return (
    <ContainerUploadImages>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4>{label ?? "Fotos *"}</h4>
        {isView === true ? (
          <div
            onClick={() => {
              reverEditImages();
            }}
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            reverter fotos
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="box-container-photos">
        <label className="box-photo" htmlFor="files">
          +
          <input
            type="file"
            id="files"
            accept="image/png, image/jpeg, image/jpg"
            multiple
            {...register("images", {
              onChange: async (v) => {
                updateState(
                  await imageManager.getBase64(watch("images"), () => {
                    setIsLoadingImages(false);
                  })
                );
              },
            })}
          />
        </label>
        {watch("images")?.length !== 0
          ? isLoadingImages
            ? Array.from(
                {
                  length: imagesToArray.length,
                },
                function (v, k) {
                  return k;
                }
              ).map((_v, _i) => {
                return (
                  <div className="box-photo" key={_i}>
                    <Spinner color="#000" />
                  </div>
                );
              })
            : imagesToArray.map((_v: any, _i: number) => {
                return (
                  <ContainerImageMiniPhoto
                    key={_i}
                    src={_v.data as string}
                    onClick={() => {
                      onRemoveImageFromArray(_i);
                    }}
                    className={[
                      _v["size"] > 8699010 ? "disabled" : "",
                      isView === true ? "isView" : "",
                    ].join("")}
                  />
                );
              })
          : [0, 1, 2, 3, 4, 5, 6, 7, 8].map((_v, _i) => {
              return (
                <div className="box-photo" key={_i}>
                  <HiOutlinePhotograph />
                </div>
              );
            })}
      </div>
      <div style={{ paddingTop: 20 }}>Máximo 8mb por imagem.</div>
    </ContainerUploadImages>
  );
}
