import { gsap } from "gsap";
import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DefaultReducerProps } from "@src/store/reducers";
import {
  setReduxElementBigModal,
  setReduxTitleBigModal,
} from "@src/store/reducers/bigModal/actions";
import { IReduxBigModal } from "@src/store/reducers/bigModal/props";

export type openBigModalProps = {
  title: string;
  element: JSX.Element;
  isFull?: boolean;
  hiddenHead?: boolean;
  icon?: ReactNode;
};

export default function useBigModal() {
  const dispatch = useDispatch();

  const { title, element, icon } = useSelector(
    (state: DefaultReducerProps) => state.bigModal as IReduxBigModal
  );

  const getContentBigModal = window.document.getElementsByClassName(
    "class-content-big-modal"
  );

  const getRealBigModal = window.document.getElementsByClassName(
    "real-white-big-modal"
  );

  const getRealHeaderBigModal =
    window.document.getElementsByClassName("header-big-modal");

  const getRealChildrenBigModal =
    window.document.getElementsByClassName("big-modal");

  function openBigModal({
    element,
    title,
    isFull,
    icon: iconed,
    hiddenHead,
  }: openBigModalProps) {
    realOpenBigModal({ element, title, icon: iconed });

    gsap.to(getContentBigModal, {
      zIndex: 99999,
      duration: 0.1,
      onComplete: () => {
        gsap.to(getContentBigModal, {
          opacity: 1,
          duration: 0.3,
          onComplete: () => {
            if (isFull) {
              gsap.to(getRealBigModal, {
                maxWidth: "100%",
                duration: 0.1,
              });
            }
            gsap.to(getRealBigModal, {
              opacity: 1,
              marginRight: 0,
              duration: 0.2,
              delay: 0.1,
              onComplete: () => {
                if (hiddenHead) {
                  gsap.to(getRealHeaderBigModal, {
                    display: "none",
                    duration: 0.1,
                    onComplete: () => {
                      gsap.to(getRealChildrenBigModal, {
                        opacity: 1,
                        marginTop: 0,
                        duration: 0.2,
                      });
                    },
                  });
                } else {
                  gsap.to(getRealHeaderBigModal, {
                    opacity: 1,
                    marginTop: 0,
                    duration: 0.2,
                    onComplete: () => {
                      gsap.to(getRealChildrenBigModal, {
                        opacity: 1,
                        marginTop: 0,
                        duration: 0.2,
                      });
                    },
                  });
                }
              },
            });
          },
        });
      },
    });
  }

  async function realOpenBigModal({ element, title, icon }: openBigModalProps) {
    dispatch(setReduxTitleBigModal(title, icon));
    dispatch(setReduxElementBigModal(element));
  }

  function closeBigModal() {
    gsap.to(getRealChildrenBigModal, {
      opacity: 0,
      marginTop: 4,
      duration: 0.2,
      onComplete: () => {
        gsap.to(getRealHeaderBigModal, {
          opacity: 0,
          marginTop: 4,
          duration: 0.2,
          onComplete: () => {
            realOpenBigModal({ element: <></>, title: "" });
            gsap.to(getRealBigModal, {
              maxWidth: "500px",
              duration: 0.1,
            });
            gsap.to(getRealBigModal, {
              opacity: 0,
              marginRight: -20,
              duration: 0.2,
              onComplete: () => {
                gsap.to(getContentBigModal, {
                  opacity: 0,
                  duration: 0.2,
                  onComplete: () => {
                    gsap.to(getContentBigModal, {
                      zIndex: "-99999",
                      duration: 0.2,
                    });
                  },
                });
              },
            });
          },
        });
      },
    });
  }

  return {
    titleBigModal: title,
    elementBigModal: element,
    iconBigModal: icon,
    openBigModal,
    closeBigModal,
  };
}
