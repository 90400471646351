import { gsap } from "gsap";

export default function useShowHidePage() {
  function show(selector: string) {
    const selectored = window.document.querySelector(selector);
    gsap.to(selectored, {
      opacity: 1,
      duration: 0.4,
      delay: 0.2,
    });
    gsap.to(selectored, {
      marginTop: 0,
      duration: 0.4,
      delay: 0.2,
    });
  }

  return {
    showPage: show,
  };
}
