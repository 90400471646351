import styled from "styled-components";

export const ContainerCardAlbum = styled.div<{ image: string }>`
  width: 100%;
  max-width: 260px;

  height: 400px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 5px;

  .content-top {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .album-image {
      width: 100%;

      aspect-ratio: 1 / 1;

      background-color: rgba(0, 0, 0, 0.1);

      border-radius: 5px;

      background-image: url(${(props) => props.image});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      cursor: pointer;
    }

    .album-title {
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      display: flex;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .album-description {
      font-size: 16px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.6);

      display: flex;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .album-buttons {
    display: flex;
    margin-top: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.4);
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    /* padding: 10px 0px; */
    .album-button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      padding: 8px 0px 5px;

      color: rgba(0, 0, 0, 0.7);

      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
      .album-button-label {
        margin-bottom: 2px;
        font-size: 15px;
      }
    }
  }
`;
