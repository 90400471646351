import styled from "styled-components";

export const ContainerUploadImages = styled.div`
  width: 100%;

  h4 {
    margin-bottom: -10px !important;
  }

  .box-container-photos {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    gap: 20px;

    overflow-x: scroll;
    overflow-y: hidden;

    margin-top: 14px;
    padding-top: 15px;

    &::-webkit-scrollbar {
      display: none;
    }

    .loading-overflow {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background-color: rgba(0, 0, 0, 0.1);

      z-index: -9999;

      &.front {
        z-index: 9999;
      }
    }

    .box-photo {
      background-color: rgba(0, 0, 0, 0.1);
      padding: 20px;

      width: 140px;
      min-width: 140px;
      height: 140px;
      min-height: 140px;

      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 34px;
      color: rgba(0, 0, 0, 0.6);

      border-radius: 5px;

      cursor: pointer;

      input[type="file"] {
        opacity: 0;
        display: none;
      }
    }
  }
`;

export const ContainerImageMiniPhoto = styled.div<{
  src: string;
  close?: boolean;
}>`
  width: 140px;
  min-width: 140px;
  height: 140px;
  min-height: 140px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  border-radius: 5px;

  position: relative;

  &.disabled {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &.isView {
    &:hover {
    }
  }

  &:hover {
    &::after {
      content: "x";
      position: absolute;
      top: -15px;
      right: -15px;

      width: 30px;
      height: 30px;

      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 4px;

      border-radius: 20px;

      background-color: var(--color-active);

      cursor: pointer;

      color: white;

      z-index: 99;
    }
  }
`;
