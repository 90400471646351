import { useDispatch, useSelector } from "react-redux";

import { DefaultReducerProps } from "@src/store/reducers";
import { INooxven } from "@src/types/entity/nooxven";
import { setReduxNooxven } from "@src/store/reducers/nooxven/actions";

export default function useNooxven() {
  const dispatch = useDispatch();

  const { nooxven } = useSelector((state: DefaultReducerProps) => state);

  function setNooxven(c: INooxven) {
    dispatch(setReduxNooxven(c));
  }

  return {
    nooxven: nooxven?.nooxven,
    setNooxven,
  };
}
