import Spinner from "../Spinner";

export type ButtonProps = {
  label?: string;
  type?: "button" | "submit" | "reset";
  isLoading?: boolean;
  onClick?: () => void;
};

export default function Button({
  label,
  type,
  onClick,
  isLoading,
}: ButtonProps) {
  return (
    <button
      type={type}
      onClick={() => {
        if (isLoading === true) {
          return;
        }
        onClick && onClick();
      }}
    >
      {isLoading ? <Spinner /> : label}
    </button>
  );
}
