import React from "react";
import styled from "styled-components";

export type LayoutAuthProps = {
  children: React.ReactNode;
};

export default function LayoutAuth({ children }: LayoutAuthProps) {
  return <ContainerLayoutAuth>{children}</ContainerLayoutAuth>;
}

export const ContainerLayoutAuth = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: white;

  .general-input {
    max-width: 400px;
  }

  button {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    border-radius: 0;
    max-width: 100%;
    align-items: flex-start;
  }
`;
