/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import AppLogo from "@src/components/Logo";
import useBigModal from "@src/hooks/useBigModal";

import useNooxven from "@src/hooks/useNooxven";
import BigModal from "@src/components/BigModal";

import { NooxvenInstance } from "..";
import { ContainerLayoutApp } from "./styles";
import { dataMenuLayoutApp } from "./data_layout_app";

export type LayoutAppProps = {
  children: React.ReactNode;
};

export default function LayoutApp({ children }: LayoutAppProps) {
  const navigate = useNavigate();
  const { titleBigModal, elementBigModal, closeBigModal, iconBigModal } =
    useBigModal();

  const { nooxven } = useNooxven();

  useEffect(() => {
    (() => {
      const token = window.localStorage.getItem(
        NooxvenInstance.getDefaultToken()
      );

      if (!token) {
        window.localStorage.clear();
        navigate("/");

        return;
      }
    })();
  }, []);

  return (
    <ContainerLayoutApp>
      <div className="body-app">
        <div className="body-app-left">
          <div className="body-app-left-top">
            <AppLogo appName={nooxven?.name ?? "..."} />
            <div className="item-hover-separator full" />
            {dataMenuLayoutApp.map((item, key) => {
              return (
                <div key={key}>
                  <div
                    className="item-hover"
                    onClick={() => navigate(item.path ?? "/")}
                  >
                    <span className="icon-span">{item.icon ?? <></>}</span>
                    <span className="label-span">{item.label}</span>
                  </div>
                  {item.separator ? (
                    <>
                      <div className="item-hover-separator" />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          </div>
          <div className="body-app-left-bottom">
            <div
              className="item-hover"
              onClick={() => {
                window.localStorage.clear();
                navigate("/");
              }}
            >
              <span className="icon-span">
                <FiLogOut />
              </span>
              <span className="label-span">Sair</span>
            </div>
          </div>
        </div>
        <div className="body-app-right">
          {children}
          <BigModal
            title={titleBigModal}
            element={elementBigModal}
            icon={iconBigModal}
            onClose={closeBigModal}
          />
        </div>
      </div>
    </ContainerLayoutApp>
  );
}
