import React from "react";
import { ContainerInputSelect } from "./styles";

export type InputSelectProps = {
  options: InputSelectOptionsProps[];
  onChange: (selected: string | number) => void;
  selected: string | number;
};

export type InputSelectOptionsProps = {
  value: string | number;
  label: string;
};

export default function InputSelect({
  options,
  onChange,
  selected,
}: InputSelectProps) {
  return (
    <ContainerInputSelect
      onChange={(event) => {
        onChange(event.target.value);
      }}
      defaultValue={selected ?? "NO_CATEGORY"}
    >
      {options.length > 0 ? (
        options.map((value, _index) => {
          return (
            <option key={_index} value={value.value}>
              {value.label}
            </option>
          );
        })
      ) : (
        <></>
      )}
    </ContainerInputSelect>
  );
}
