import styled from "styled-components";

export const ContaienrSuccessBox = styled.div`
  width: 100%;
  height: 400px;

  border-radius: 5px;

  border: 2px solid #c1c1c1;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;

  .icon {
    font-size: 52px;
  }
  color: green;
`;
