import React from "react";
import { ContainerCardHome } from "./styles";

export type CardHomeProps = {
  title?: string;
  value?: string;
};

export default function CardHome({ title, value }: CardHomeProps) {
  return (
    <ContainerCardHome>
      <div className="title-card-home">{title}</div>
      <div className="valued">{value}</div>
    </ContainerCardHome>
  );
}
