/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { HiOutlinePhotograph } from "react-icons/hi";

import Spinner from "../Spinner";
import { IAlbumImage } from "@src/types/entity/album";
import { ContainerUploadImages, ContainerImageMiniPhoto } from "./styles";
import { ImagesManager } from "./images_manager";

export type UploadImagesProps = {
  images: IAlbumImage[];
  onChange: (images: IAlbumImage[]) => void;
};

export default function UploadImagesV2({
  images,
  onChange,
}: UploadImagesProps) {
  const [isLoadingImages, setIsLoadingImages] = useState<boolean>(true);

  const [localImages, setLocalImages] = useState<IAlbumImage[]>([]);
  const [localImagesBackUp, setLocalImagesBackUp] = useState<IAlbumImage[]>([]);

  const imageManager = new ImagesManager();

  useEffect(() => {
    onCreate();
  }, []);

  function onCreate() {
    setLocalImages(images);
    setLocalImagesBackUp(images);
    setTimeout(() => {
      setIsLoadingImages(false);
    }, 2000);
  }

  async function onChangeInput(event: React.ChangeEvent<HTMLInputElement>) {
    const oldImages = localImages;

    const fileList: FileList | null = event.target.files;

    if (fileList === null) {
      return;
    }

    setTimeout(() => {}, 3000);

    const newImages = await imageManager.getBase64(
      fileList,
      () => {
        setIsLoadingImages(true);
      },
      () => {
        setIsLoadingImages(false);
      }
    );

    const valueToReturn = [...newImages, ...oldImages];

    setLocalImages(valueToReturn);

    callOnChangeEvent(valueToReturn);
  }

  function onRemoveImage(fileName: String) {
    const oldImages = localImages;

    const valueToReturn = [];

    for (var i = 0; i < oldImages.length; i++) {
      var imageToAdd = oldImages[i];

      const isIndexSelected = oldImages[i].name === fileName;

      if (isIndexSelected) {
        if (imageToAdd.toDelete !== undefined) {
          imageToAdd.toDelete = undefined;
        } else {
          imageToAdd.toDelete = true;
        }
      }

      valueToReturn.push(imageToAdd);
    }

    setLocalImages(valueToReturn);

    callOnChangeEvent(valueToReturn);
  }

  function onClickBackup() {
    const oldImages = localImagesBackUp;

    const valueToReturn = [];
    setLocalImages([]);

    for (var i = 0; i < oldImages.length; i++) {
      var imageToAdd = oldImages[i];

      if (imageToAdd.toDelete !== undefined) {
        imageToAdd.toDelete = undefined;
      }

      valueToReturn.push(imageToAdd);
    }

    setLocalImages(localImagesBackUp);
    callOnChangeEvent(localImagesBackUp);
  }

  function callOnChangeEvent(images: IAlbumImage[]) {
    onChange(images);
  }

  return (
    <ContainerUploadImages>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4>{"Fotos *"}</h4>
        <div
          onClick={() => {
            onClickBackup();
          }}
          style={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          reverter fotos
        </div>
      </div>
      <div className="box-container-photos">
        <label className="box-photo" htmlFor="files">
          +
          <input
            type="file"
            id="files"
            accept="image/png, image/jpeg, image/jpg"
            onChange={(event) => {
              onChangeInput(event);
            }}
            multiple
          />
        </label>
        {localImages?.length !== 0
          ? isLoadingImages
            ? Array.from(
                {
                  length: localImages.length,
                },
                function (v, k) {
                  return k;
                }
              ).map((_v, _i) => {
                return (
                  <div className="box-photo" key={_i}>
                    <Spinner color="#000" />
                  </div>
                );
              })
            : localImages
                // .filter((v) => v.toDelete !== true)
                .map((_v, _i: number) => {
                  return (
                    <ContainerImageMiniPhoto
                      key={_i}
                      src={_v.data as string}
                      toDelete={_v.toDelete}
                      className={[
                        _v.toDelete !== undefined ? "toDelete" : "",
                      ].join(" ")}
                      onClick={() => {
                        onRemoveImage(_v.name);
                      }}
                    />
                  );
                })
          : [0, 1, 2, 3, 4, 5, 6, 7, 8].map((_v, _i) => {
              return (
                <div className="box-photo" key={_i}>
                  <HiOutlinePhotograph />
                </div>
              );
            })}
      </div>
      <div style={{ paddingTop: 20 }}>Máximo 8mb por imagem.</div>
    </ContainerUploadImages>
  );
}
