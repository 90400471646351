import { FiClipboard, FiX } from "react-icons/fi";

import { IReduxBigModal } from "@src/store/reducers/bigModal/props";

import { ContainerBigModal } from "./styles";

export type BigModalProps = {
  onClose?: () => void;
} & IReduxBigModal;

export default function BigModal({
  title,
  element,
  onClose,
  icon,
}: BigModalProps) {
  return (
    <ContainerBigModal className="class-content-big-modal">
      <div className="real-white-big-modal">
        <div className="header-big-modal">
          <div className="left-header-big-modal">
            <div className="header-icon-big-modal">
              {icon ?? <FiClipboard />}
            </div>
            <div className="header-title-big-modal">
              {title ?? "Sem titulo"}
            </div>
          </div>
          <div className="right-header-big-modal" onClick={onClose}>
            <FiX />
          </div>
        </div>
        <div className="big-modal">{element}</div>
      </div>
    </ContainerBigModal>
  );
}
