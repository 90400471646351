import { Routes, Route } from "react-router-dom";

import LayoutApp from "@src/layouts/LayoutApp";
import RoutesAppIndex from "@src/containers/app";

export default function RoutesApp() {
  return (
    <LayoutApp>
      <Routes>
        <Route
          path="/*"
          element={
            <Routes>
              <Route path="/" element={<RoutesAppIndex />} />
            </Routes>
          }
        />
      </Routes>
    </LayoutApp>
  );
}
