import React from "react";
import styled from "styled-components";

export default function Spinner({ color }: { color?: string }) {
  return <ContainerSpinner id="loading" color={color}></ContainerSpinner>;
}

const ContainerSpinner = styled.div<{ color?: string }>`
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: ${(props) => props.color ?? "#fff"};
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;
