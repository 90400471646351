import styled from "styled-components";

export const ContainerLogin = styled.form`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .top {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    padding: 20px;
    padding-top: 16px;

    gap: 20px;

    .content-app-logo {
      padding-top: 10px;
      margin-bottom: 10px;
    }

    h1 {
      font-size: 44px;
    }

    p {
      font-weight: 300;
      letter-spacing: 0.5px;

      margin-top: 6px;
      margin-bottom: 16px;

      b {
        font-weight: 400;
      }
    }

    .content-message-error {
      width: 100%;
      max-width: 400px;
      display: flex;

      padding: 16px;

      color: rgb(95, 33, 32);
      background-color: rgb(253, 237, 237);

      border-radius: 5px;

      cursor: pointer;
    }

    .link-forgot-password {
      width: 100%;
      max-width: 400px;
      display: flex;
      justify-content: flex-end;

      font-size: 15px;
      color: #828282;

      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: var(--color-active);
      }

      &.link-back {
        justify-content: center;
        margin-top: 10px;
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-around;

    gap: 20px;

    padding-bottom: 30px;
    color: #828282;

    .link {
      cursor: pointer;
    }
  }

  @media (max-width: 768px) {
    .top {
      align-items: flex-start;
      h1 {
        font-size: 30px;
      }
      p {
        margin-top: 0px;
        margin-bottom: 6px;
      }

      .content-app-logo {
        margin-bottom: 0px;
      }
    }
  }
`;
