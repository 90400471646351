import styled from "styled-components";

export const ContainerAppLogo = styled.div`
  width: 100%;
  max-width: 400px;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 14px;

  cursor: pointer;

  span {
    font-size: 24px;
    font-weight: bold;

    color: var(--color-active);
  }

  .content-icon {
    width: 50px;
    height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--color-active);

    border-radius: 8px;

    svg {
      font-size: 34px;
      color: white;
    }
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;
