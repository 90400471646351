import { INITIAL_STATE } from "./data";
import { ActionProps, IReduxEmployee } from "./props";

export default function reducer(
  state = INITIAL_STATE,
  action: ActionProps
): IReduxEmployee {
  if (action.type === "SET_EMPLOYEE") {
    return {
      ...state,
      employee: action.employee,
    };
  }

  return state;
}
