import axios from "axios";
import { globalData } from "../global/index";

const instanceApi = axios.create({
  baseURL: globalData.apiUrl,
  timeout: 240000,
});

instanceApi.interceptors.request.use((config) => {
  // @ts-ignore
  config.headers.authorization = `Bearer ${localStorage.getItem("4mr_token")}`;

  return config;
});

export default instanceApi;
