import React from "react";
import { FiGrid } from "react-icons/fi";

export type MenuLayoutApp = {
  id?: string;
  label?: string;
  path?: string;
  icon?: React.ReactNode;
  separator?: boolean;
};

export const dataMenuLayoutApp: MenuLayoutApp[] = [
  {
    id: "01",
    label: "Galeria de Imagens",
    path: "/app",
    icon: <FiGrid />,
    // separator: true,
  },
  // {
  //   id: "10",
  //   label: "Ajustes",
  //   path: "/app/settings",
  //   icon: <FiSettings />,
  // },
];
