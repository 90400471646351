import { useState } from "react";
import { useForm } from "react-hook-form";

import Input from "@src/components/Input";
import Textarea from "@src/components/Textarea";
import Button from "@src/components/Button";

import { ContainerFormCreateAlbum } from "./styles";
import useBigModal from "@src/hooks/useBigModal";
import {
  IAlbumImage,
  IAlbum,
  AlbumTypeEnumType,
} from "@src/types/entity/album";
import useAlbuns from "@src/hooks/useAlbuns";
import InputSelect from "../InputSelect";
import UploadImages from "../UploadImages";
import SuccessBox from "../SuccessBox";

export type FormCreateProps = IAlbum & {
  images: FileList;
};

export default function FormCreateAlbum() {
  const { closeBigModal } = useBigModal();
  const { createAlbum } = useAlbuns();

  const { register, handleSubmit, watch, setValue } = useForm<FormCreateProps>({
    defaultValues: {
      images: [] as any,
      imageCover: 1,
    },
  });

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<string | undefined>(undefined);
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);

  async function onSubmit(data: FormCreateProps) {
    setIsLoadingButton(true);

    try {
      const albumToCreate: IAlbum = {
        title: data.title,
        description: data.description,
        imageCover: Number(data.imageCover ?? 1),
        type: data.type ?? "CONSTRUCTION",
        images: data.images,
      };

      if (
        !albumToCreate.title ||
        !albumToCreate.images ||
        !albumToCreate.type
      ) {
        console.log({
          albumToCreate,
        });
        alert("O Nome e pelo menos uma foto é obrigatório.");
        setIsLoadingButton(false);
        return;
      }
      console.log({
        albumToCreate,
      });

      const creadted = await createAlbum(albumToCreate);

      if (creadted === true) {
        setIsSuccess(true);
        setIsLoadingButton(false);
      }
    } catch (error) {
      setIsError("Algo deu errado. Tente novamente mais tarde.");
      setIsLoadingButton(false);
    }
  }

  return (
    <ContainerFormCreateAlbum onSubmit={handleSubmit(onSubmit)}>
      {isSuccess === true ? (
        isError ? (
          <>
            {isError}
            <div className="float-button">
              <Button
                label="Voltar"
                type="button"
                onClick={() => {
                  setIsSuccess(false);
                  setIsError(undefined);
                }}
              />
            </div>
          </>
        ) : (
          <>
            <SuccessBox />
            <div className="float-button">
              <Button
                label="Fechar"
                type="button"
                onClick={() => {
                  closeBigModal();
                }}
              />
            </div>
          </>
        )
      ) : (
        <>
          <h4>Título do Álbum</h4>
          <Input
            hookForm={{
              ...register("title"),
              ...{ placeholder: "Título do Álbum", type: "text" },
            }}
          />
          <br />
          <h4>Descrição do Álbum</h4>
          <Textarea
            hookForm={{
              ...register("description"),
              ...{
                placeholder: "Descrição do trabalho realizado.",
                type: "text",
              },
            }}
          />
          <br />
          {/* Label Images */}
          <UploadImages
            label="Fotos do Álbum"
            watch={watch}
            register={register}
            onChange={(items) => {
              const v = items as FileList & IAlbumImage[];

              // @ts-ignore
              setValue("images", v);
            }}
          />
          <br />
          {/* Label Categories */}
          <h4>Foto de Capa</h4>
          <div className="label-form-price-item">
            <InputSelect
              options={Array.from(
                { length: watch("images") ? watch("images").length : 1 },
                (_, i) => i + 1
              ).map((v, _i) => {
                return {
                  label: `${_i + 1}`,
                  value: `${_i + 1}`,
                };
              })}
              // @ts-ignore
              selected={watch("imageCover") ?? 1}
              onChange={(selected) => {
                setValue("imageCover", selected as number);
              }}
            />
          </div>
          <br />
          {/* Label Categories */}
          <h4>Tipo do Trabalho</h4>
          <div className="label-form-price-item">
            <InputSelect
              options={[
                {
                  label: "Construção Civíl",
                  value: "CONSTRUCTION",
                },
                {
                  label: "Serralharia",
                  value: "SAWMILL",
                },
                {
                  label: "Elétrica",
                  value: "ELECTRICAL",
                },
              ]}
              // @ts-ignore
              selected={(watch("type") as AlbumTypeEnumType) ?? "CONSTRUCTION"}
              onChange={(selected) => {
                const va = selected as AlbumTypeEnumType;
                // @ts-ignore
                setValue("type", va);
              }}
            />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className="float-button">
            <Button label="Criar Álbum" isLoading={isLoadingButton} />
          </div>
        </>
      )}
    </ContainerFormCreateAlbum>
  );
}
