import { useDispatch, useSelector } from "react-redux";

import { IAlbum } from "@src/types/entity/album";
import { DefaultReducerProps } from "@src/store/reducers";
import { setReduxAlbuns } from "@src/store/reducers/album/actions";
import { AlbumServiceApi } from "@src/services/album.service";
import { IRequestApi, ResponseApi } from "@src/types/api";

const albumService = new AlbumServiceApi();

export default function useAlbuns() {
  const dispatch = useDispatch();

  const { albuns } = useSelector((state: DefaultReducerProps) => state);

  function setAlbuns(c: IAlbum[]) {
    dispatch(setReduxAlbuns(c));
  }

  async function createAlbum(album: IAlbum): Promise<boolean> {
    album.createdAt = new Date();
    const oldAlbuns = albuns?.albuns ?? [];

    try {
      const response: ResponseApi<IAlbum> = await albumService.create({
        set: album,
      });

      if (response.ok === true) {
        const oldI = album.images ?? [];

        var itemToset = album;

        const realResult: IAlbum = response.result as IAlbum;

        for (var imageIndex in oldI) {
          oldI[Number(imageIndex)].data = response.result
            ? realResult.images && realResult.images[Number(imageIndex)].data
            : "";
        }

        itemToset["_id"] = realResult._id;

        itemToset["images"] = oldI;

        setAlbuns([...[itemToset], ...oldAlbuns]);
        return true;
      } else {
        throw new Error("Aldo deu arrado X1");
      }
    } catch (error) {
      return false;
    }
  }

  async function retrieveAlbums(
    data?: IRequestApi<IAlbum | IAlbum[]>,
    update?: boolean,
    refresh?: boolean
  ) {
    update = update ?? (albuns?.albuns && albuns?.albuns.length <= 0);
    if (update && update === true) {
      if (data?.returnMany) {
        const d = await returnMany(data as IRequestApi<IAlbum[]>);
        const dataToset = populateList(
          refresh ? [] : (albuns?.albuns as IAlbum[]),
          d
        );

        setAlbuns(dataToset);
        return;
      } else {
        returnOne(data as IRequestApi<IAlbum>);
        return;
      }
    }
  }

  async function updateAlbum(
    data: IRequestApi<IAlbum>
  ): Promise<IAlbum | IAlbum[] | undefined> {
    const response = await albumService.update(data);

    if (response.ok) {
      return response.result;
    }

    return undefined;
  }

  async function deleteAlbum(id: string) {
    const made = await albumService.delete(id);

    if (made.ok) {
      const oldData: IAlbum[] = albuns?.albuns ?? [];

      var empt = [];

      for (var i = 0; i < oldData.length; i++) {
        if (id !== oldData[i]._id) {
          empt.push(oldData[i]);
        }
      }

      setAlbuns(empt);
    }
  }

  function populateList(old: IAlbum[], newO: IAlbum[]): IAlbum[] {
    if (old.length === 0) {
      return newO;
    } else {
      var empt: IAlbum[] = old;
      for (var i = 0; i < newO.length; i++) {
        if (
          !JSON.stringify({ _id: newO[i]._id }).includes(JSON.stringify(old))
        ) {
          empt.push(newO[i]);
        }
      }
      return old;
    }
  }

  async function returnOne(data?: IRequestApi<IAlbum>): Promise<IAlbum> {
    const response = await albumService.read(data);

    if (response.ok) {
      return response.result as IAlbum;
    }
    return {};
  }

  async function returnMany(data?: IRequestApi<IAlbum[]>): Promise<IAlbum[]> {
    const response = await albumService.read(data);
    if (response.ok) {
      return response.result as IAlbum[];
    }
    return [];
  }

  return {
    albuns: albuns?.albuns,
    retrieveAlbums,
    setAlbuns,
    createAlbum,
    updateAlbum,
    deleteAlbum,
  };
}
