import React from "react";

import { ContainerTextarea } from "./styles";

export type TextareaProps = {
  hookForm?: any;
};

export default function Textarea({ hookForm }: TextareaProps) {
  return (
    <ContainerTextarea className="general-input">
      <textarea {...hookForm} />
    </ContainerTextarea>
  );
}
