import ReactDOM from "react-dom/client";
import App from "./App";
import { Nooxven } from "./Nooxven";

import "./index.css";

export const NooxvenInstance = new Nooxven();

NooxvenInstance.getContract();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);
