import { FaRegEye } from "react-icons/fa";
import { BiEdit, BiTrash } from "react-icons/bi";
import { ContainerCardAlbum } from "./styles";
import { IAlbum } from "@src/types/entity/album";

export type CardAlbumProps = {
  album: IAlbum;
  onView?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
};

export default function CardAlbum({
  album,
  onView,
  onEdit,
  onDelete,
}: CardAlbumProps) {
  const imagesLength = album.images?.length ?? 1;
  const imageCover = album.imageCover ?? 0;

  const objectFound =
    imagesLength >= imageCover
      ? album.images !== undefined
        ? album.images[album.imageCover ? album.imageCover - 1 : 0]
        : undefined
      : undefined;

  return (
    <ContainerCardAlbum image={objectFound?.data as string}>
      <div className="content-top">
        <div className="album-image"></div>
        <div className="album-title">{album.title ?? "Sem título"}</div>
        <div className="album-description">
          {album.description ?? "Sem descrição"}
        </div>
      </div>
      <div className="album-buttons">
        <div className="album-button" onClick={onView}>
          <div className="album-button-icon">
            <FaRegEye size={14} />
          </div>
          <div className="album-button-label">Ver</div>
        </div>
        <div className="album-button" onClick={onEdit}>
          <div className="album-button-icon">
            <BiEdit size={15} />
          </div>
          <div className="album-button-label">Editar</div>
        </div>
        <div className="album-button" onClick={onDelete}>
          <div className="album-button-icon">
            <BiTrash size={14} />
          </div>
          <div className="album-button-label">Apagar</div>
        </div>
      </div>
    </ContainerCardAlbum>
  );
}
