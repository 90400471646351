import styled from "styled-components";

export const ContainerLayoutApp = styled.div`
  width: 100%;
  height: 100%;

  display: flex;

  background-color: #fcfcfc;

  .body-app {
    width: 100%;
    height: 100%;

    display: flex;

    .body-app-left {
      width: 300px;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      background-color: var(--bg-color-ptimary);

      color: white;

      padding-bottom: 20px;

      .content-app-logo {
        margin: 30px 0px 30px;
        span {
          color: white;
        }
      }

      .item-hover-separator {
        width: calc(100% - 60px);
        height: 1px;

        background-color: rgba(255, 255, 255, 0.3);

        margin: 19.5px 30px;
      }

      .item-hover {
        width: calc(100% - 60px);

        display: flex;
        align-items: center;

        margin: 0px 30px 10px;

        padding: 4px 14px 0px;

        border-radius: 5px;

        color: rgba(255, 255, 255, 0.6);

        transition: all 0.4s ease;

        line-height: 26px;

        span.icon-span {
          margin-right: 12px;
          margin-top: 2px;
          svg {
            font-size: 17px;
          }
        }
        span.label-span {
          margin-bottom: 4px;
        }

        &:hover {
          cursor: pointer;
          color: white;
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
    .body-app-right {
      width: calc(100% - 240px);

      height: 100%;

      background-color: white;

      overflow-y: scroll;
    }
  }
`;
