import { Routes, Route } from "react-router-dom";

import RoutesAuth from "./auth";
import RoutesApp from "./private/app";

export default function DefaultRoutes() {
  return (
    <Routes>
      <Route path="/*" element={<RoutesAuth />} />
      <Route path="app/*" element={<RoutesApp />} />
    </Routes>
  );
}
