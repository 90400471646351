import React from "react";
import { ContaienrSuccessBox } from "./styled";
import { FaCheckSquare } from "react-icons/fa";

export type SuccessBoxProps = {
  title?: string;
};

export default function SuccessBox({ title }: SuccessBoxProps) {
  return (
    <ContaienrSuccessBox>
      <div className="icon">
        <FaCheckSquare />
      </div>
      <div>{title ?? "Solicitação concluída."}</div>
    </ContaienrSuccessBox>
  );
}
