import styled from "styled-components";

export const ContainerCardHome = styled.div`
  width: 100%;
  max-width: 260px;
  min-width: 260px;
  height: 100px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  background-color: #dbdbdb;

  border-radius: 5px;

  padding: 16px;

  cursor: pointer;

  .title-card-home {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.4);
  }

  .valued {
    font-size: 36px;
    font-weight: bold;
  }
`;
