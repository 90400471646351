import { IAlbumImage } from "@src/types/entity/album";

export class ImagesManager {
  public async getBase64(
    file: FileList,
    callbackStart?: () => void,
    callbackFinish?: () => void
  ): Promise<IAlbumImage[]> {
    const values = Array.from(file);

    if (callbackStart !== undefined) {
      callbackStart();
    }

    const totalItems = values.length > 10 ? 10 : values.length;

    var init: IAlbumImage[] = [];

    var imagesTooBig = [];

    for (var i = 0; i < totalItems; i++) {
      if (values[i]) {
        var reader = `${await this.getBase64Async(values[i])}`;

        var base64str = reader.substring(reader.indexOf(",") + 1);
        var decoded = atob(base64str);

        var newValueToSet = {
          data: reader,
          name: values[i].name,
          size: decoded.length < 7999910,
          realSize: decoded.length,
        };

        if (decoded.length < 7999910) {
          init.push(newValueToSet);
        } else {
          imagesTooBig.push(newValueToSet);
        }
      }
    }

    if (imagesTooBig.length > 0) {
      alert(
        `Imagens demasiadas grande: ${imagesTooBig
          .map((v) => ` [ ${v.name} ]`)
          .toString()}. Essas imagens não podem ser utilizadas.`
      );
    }

    if (callbackFinish !== undefined) {
      callbackFinish();
    }

    return init;
  }

  getBase64Async(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
}
