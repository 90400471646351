import { IRequestApi, ResponseApi } from "@src/types/api";
import { IAlbum } from "@src/types/entity/album";
import instanceApi from "./api";

export class AlbumServiceApi {
  async create(album: IRequestApi<IAlbum>): Promise<ResponseApi<IAlbum>> {
    try {
      if (!this.tokenRequestValidate()) {
        throw new Error();
      }

      const request = await instanceApi.post("/app/album/create", album);

      return request.data;
    } catch (error) {
      return {
        ok: false,
        message: "Algo deu errado. Tente novamente mais tarde.",
      };
    }
  }
  async read(
    data?: IRequestApi<IAlbum | IAlbum[]>
  ): Promise<ResponseApi<IAlbum>> {
    try {
      if (!this.tokenRequestValidate()) {
        throw new Error();
      }

      const request = await instanceApi.post("/app/album/read", data);

      return request.data;
    } catch (error) {
      return {
        ok: false,
        message: "Algo deu errado. Tente novamente mais tarde.",
      };
    }
  }

  async update(data?: IRequestApi<IAlbum>): Promise<ResponseApi<IAlbum>> {
    try {
      if (!this.tokenRequestValidate()) {
        throw new Error();
      }

      const request = await instanceApi.post("/app/album/update", data);

      return request.data;
    } catch (error) {
      return {
        ok: false,
        message: "Algo deu errado. Tente novamente mais tarde.",
      };
    }
  }

  async delete(itemId?: string): Promise<ResponseApi<IAlbum>> {
    try {
      if (!this.tokenRequestValidate()) {
        throw new Error();
      }

      const request = await instanceApi.post("/app/album/delete", {
        _id: itemId,
      });

      return request.data;
    } catch (error) {
      return {
        ok: false,
        message: "Algo deu errado. Tente novamente mais tarde.",
      };
    }
  }

  tokenRequestValidate() {
    const getDefaultToken = window.localStorage.getItem("default_token") ?? "";

    const getToken = window.localStorage.getItem(getDefaultToken);

    if (!getToken) {
      return false;
    }
    return true;
  }
}
