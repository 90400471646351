/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";

import useAlbuns from "@src/hooks/useAlbuns";
import useBigModal from "@src/hooks/useBigModal";

import Button from "@src/components/Button";

import { IAlbum } from "@src/types/entity/album";

import { ContainerFormDeleteAlbum } from "./styles";

export type FormCreateProps = {
  title: string;
  description: string;
  images: FileList;
};

export default function FormDeleteAlbum({ album }: { album: IAlbum }) {
  const { closeBigModal } = useBigModal();
  const { deleteAlbum } = useAlbuns();

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<string | undefined>(undefined);

  function onConfirmDelete() {
    setIsLoading(true);
    setTimeout(() => {
      deleteAlbum(album._id ?? "")
        .then(() => {
          setIsSuccess(true);
        })
        .catch(() => {
          setIsSuccess(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 1500);
  }

  return (
    <ContainerFormDeleteAlbum>
      {isSuccess === true ? (
        isError ? (
          <>
            {isError}
            <div className="float-button">
              <Button
                label="Voltar"
                type="button"
                onClick={() => {
                  setIsSuccess(false);
                  setIsError(undefined);
                }}
              />
            </div>
          </>
        ) : (
          <>
            Álbum apagado com sucesso.
            <div className="float-button">
              <Button
                label="Fechar"
                type="button"
                onClick={() => {
                  closeBigModal();
                }}
              />
            </div>
          </>
        )
      ) : (
        <>
          Tem certeza que deseja apagar esse Álbum?
          <div className="float-button">
            <Button
              label="Apagar mesmo assim"
              type="button"
              isLoading={isLoading}
              onClick={() => {
                onConfirmDelete();
              }}
            />
          </div>
        </>
      )}
    </ContainerFormDeleteAlbum>
  );
}
