import styled from "styled-components";

export const ContainerRoutesAppIndex = styled.div`
  height: 100%;
  .box-counter {
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    align-items: start;
    justify-content: flex-start;

    gap: 30px;

    opacity: 0;

    margin-top: 10px;
    padding-top: 20px;
  }

  .box-albuns {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 40px;
    gap: 30px;
  }
`;
