import React from "react";

import { ContainerInput } from "./styles";

export type InputProps = {
  hookForm?: any;
};

export default function Input({ hookForm }: InputProps) {
  return (
    <ContainerInput className="general-input">
      <input {...hookForm} />
    </ContainerInput>
  );
}
