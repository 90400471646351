/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { HiOutlinePhotograph } from "react-icons/hi";
import { RiDeleteBin2Fill, RiEdit2Fill, RiGalleryFill } from "react-icons/ri";

import instanceApi from "@src/services/api";

import useAlbuns from "@src/hooks/useAlbuns";
import useBigModal from "@src/hooks/useBigModal";

import useShowHidePage from "@src/utils/show_hide_page";

import Button from "@src/components/Button";
import CardHome from "@src/components/CardHome";
import CardAlbum from "@src/components/CardAlbum";
import ViewAlbumBig from "@src/components/ViewAlbumBig";
import HeaderLayout from "@src/components/HeaderLayout";
import FormEditAlbum from "@src/components/FormEditAlbum";
import FormCreateAlbum from "@src/components/FormCreateAlbum";
import FormDeleteAlbum from "@src/components/FormDeleteAlbum";

import { ContainerRoutesAppIndex } from "./styles";

export default function RoutesAppIndex() {
  const { showPage } = useShowHidePage();
  const { openBigModal } = useBigModal();
  const { albuns, retrieveAlbums } = useAlbuns();

  const [totalData, setTotalData] = useState<{
    albums?: number;
    photos?: number;
    storage?: string;
  }>({
    albums: 0,
    photos: 0,
    storage: "",
  });

  useEffect(() => {
    showPage(".list-container-app-index");
    showPage(".box-counter");

    updateData();
  }, []);

  async function updateData() {
    await retrieveAlbums({
      pagination: {
        page: 0,
        perPage: 20,
      },
      sort: {
        column: "createdAt",
        order: "desc",
      },
      returnMany: true,
    });

    const request = await instanceApi.post("/app/album/dashboard", {});

    if (request.data.ok) {
      setTotalData({
        albums: request.data.result.albums,
        photos: request.data.result.photos,
        storage: request.data.result.storage,
      });
    }
  }

  function onOpenNewAlbum() {
    openBigModal({
      title: "Novo Álbum",
      icon: <RiGalleryFill />,
      element: <FormCreateAlbum />,
      isFull: false,
    });
  }

  function onClickEditAlbum(index: number) {
    openBigModal({
      title: "Editar Álbum",
      icon: <RiEdit2Fill />,
      element: <FormEditAlbum album={albuns ? albuns[index] : {}} />,
      isFull: false,
    });
  }

  function onClickApagarAlbum(index: number) {
    openBigModal({
      title: "Apagar Álbum",
      icon: <RiDeleteBin2Fill />,
      element: <FormDeleteAlbum album={albuns ? albuns[index] : {}} />,
      isFull: false,
    });
  }

  function onClickViewAlbum(index: number) {
    const selected = albuns ? albuns[index] : {};
    openBigModal({
      title: `${selected?.title}`,
      icon: <HiOutlinePhotograph />,
      element: <ViewAlbumBig album={selected} />,
      isFull: true,
    });
  }

  return (
    <ContainerRoutesAppIndex className="content-app-index">
      <HeaderLayout
        title="Galeria de Imagens"
        description="Aqui estão alguns números da Galeria de Imagens"
        actionButtons={<Button label="Novo Álbum" onClick={onOpenNewAlbum} />}
      >
        <div className="box-counter">
          <CardHome title="Álbuns" value={totalData.albums?.toString()} />
          <CardHome title="Fotos" value={totalData.photos?.toString()} />
          <CardHome
            title="armazenamento"
            value={totalData.storage?.toString()}
          />
        </div>
      </HeaderLayout>
      <div className="box-albuns">
        {albuns?.map((album, _index) => {
          return (
            <CardAlbum
              key={_index}
              onEdit={() => {
                onClickEditAlbum(_index);
              }}
              onDelete={() => {
                onClickApagarAlbum(_index);
              }}
              onView={() => {
                onClickViewAlbum(_index);
              }}
              album={album}
            />
          );
        })}
      </div>
    </ContainerRoutesAppIndex>
  );
}
