import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import store from "./store";
import DefaultRoutes from "./routes/routes";

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <DefaultRoutes />
      </Provider>
    </BrowserRouter>
  );
}

export default App;
